<template>
  <div>
    <Tabs :value="$route.name" @on-click="goToTab">
      <TabPane
        v-for="{ name, meta: { labelKey } } in tabs"
        :key="name"
        :label="$t(labelKey)"
        :name="name"
      />
    </Tabs>
    <router-view></router-view>
    <DeleteUserModal />
  </div>
</template>

<script>
import routes from "./routes";
import DeleteUserModal from "./components/DeleteUserModal/DeleteUserModal.vue";

export default {
  components: { DeleteUserModal },
  computed: { tabs: () => routes },
  methods: {
    goToTab(name) {
      // the .catch is to prevent a useless "NavigationDuplicated" error in
      // console when the user try to go to the current location
      this.$router.push({ name }).catch(() => {});
    }
  }
};
</script>
