<template>
  <Button
    :loading="loading"
    type="error"
    class="mt-5"
    @click="openModal = true"
  >
    {{ $t("REMOVE_USER") }}
    <Modal v-model="openModal" @on-ok="deleteUser">
      <template #header>
        <fa
          icon="exclamation-triangle"
          size="lg"
          class="text-yellow-500 mr-3"
        />
        {{ $t("WARNING") }}
      </template>
      {{ `${$t("YOU_ARE_ABOUT_TO_REMOVE_THIS_USER")}. ${$t("ARE_YOU_SURE")}?` }}
    </Modal>
  </Button>
</template>

<script>
import { Button, Modal } from "view-design";
import deleteUser from "./queries/deleteUser.gql";
import usersQuery from "@/Users/Users/queries/users.gql";

export default {
  components: { Button, Modal },
  data: () => ({ loading: false, error: null, openModal: false }),
  methods: {
    deleteUser() {
      this.loading = true;

      this.$apollo
        .mutate({
          mutation: deleteUser,
          variables: { id: this.$route.params.userId },
          refetchQueries: [
            {
              query: usersQuery,
              variables: { search: "", page: { current: 1, size: 10 } }
            }
          ]
        })
        .then(() => {
          this.$Notice.success({ title: this.$t("SUCCESS"), duration: 5 });
          this.$router.push({ name: "/users" });
        })
        .catch(({ graphQLErrors, networkError }) =>
          this.$Notice.error({
            title: this.$t("ERROR"),
            desc: JSON.stringify(networkError || graphQLErrors),
            duration: 0
          })
        )
        .finally(() =>
          Object.assign(this, { loading: false, openModal: false })
        );
    }
  }
};
</script>
